var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-search-box",
        { on: { enter: _vm.getList } },
        [
          _c("template", { slot: "search" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-text", {
                  attrs: { label: "LBL0003254", name: "materialName" },
                  model: {
                    value: _vm.searchParam.materialName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "materialName", $$v)
                    },
                    expression: "searchParam.materialName",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-select", {
                  attrs: {
                    codeGroupCd: "MATERIAL_KIND_CD",
                    type: "search",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "materialKindCd",
                    label: "자재구분",
                  },
                  model: {
                    value: _vm.searchParam.materialKindCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "materialKindCd", $$v)
                    },
                    expression: "searchParam.materialKindCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-material-type", {
                  attrs: {
                    type: "search",
                    label: "자재분류",
                    name: "materialTypeCd",
                  },
                  model: {
                    value: _vm.searchParam.materialTypeCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "materialTypeCd", $$v)
                    },
                    expression: "searchParam.materialTypeCd",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8" },
          [
            _c(
              "c-table",
              {
                ref: "materialTable",
                attrs: {
                  title: "LBL0003256",
                  tableId: "materialTable",
                  columns: _vm.grid.columns,
                  data: _vm.grid.data,
                  columnSetting: false,
                  editable: _vm.editable,
                },
                on: { rowClick: _vm.rowClick },
              },
              [
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _c("c-btn", {
                          attrs: { label: "LBLSEARCH", icon: "search" },
                          on: { btnClicked: _vm.getList },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
          [
            _c(
              "q-form",
              { ref: "editForm" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "LBLDETAIL" },
                  },
                  [
                    _c(
                      "template",
                      { slot: "card-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _vm.editable
                              ? _c("c-btn", {
                                  attrs: { label: "LBLREG", icon: "add" },
                                  on: { btnClicked: _vm.addData },
                                })
                              : _vm._e(),
                            _vm.editable && _vm.dataeditable && _vm.updateMode
                              ? _c("c-btn", {
                                  attrs: { label: "LBLREMOVE", icon: "remove" },
                                  on: { btnClicked: _vm.deleteData },
                                })
                              : _vm._e(),
                            _vm.editable && _vm.saveable
                              ? _c("c-btn", {
                                  attrs: {
                                    url: _vm.saveUrl,
                                    isSubmit: _vm.isSave,
                                    param: _vm.data,
                                    mappingType: _vm.saveType,
                                    label: "LBLSAVE",
                                    icon: "save",
                                  },
                                  on: {
                                    beforeAction: _vm.saveMaterial,
                                    btnCallback: _vm.saveCallback,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("c-text", {
                            attrs: {
                              required: true,
                              editable: _vm.editable && _vm.dataeditable,
                              label: "자재관리번호",
                              name: "materialNo",
                            },
                            model: {
                              value: _vm.data.materialNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "materialNo", $$v)
                              },
                              expression: "data.materialNo",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("c-text", {
                            attrs: {
                              required: true,
                              editable: _vm.editable && _vm.dataeditable,
                              label: "LBL0003254",
                              name: "materialName",
                            },
                            model: {
                              value: _vm.data.materialName,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "materialName", $$v)
                              },
                              expression: "data.materialName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("c-material-type", {
                            attrs: {
                              editable: _vm.editable && _vm.dataeditable,
                              type: "edit",
                              label: "자재분류",
                              name: "materialTypeCd",
                            },
                            model: {
                              value: _vm.data.materialTypeCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "materialTypeCd", $$v)
                              },
                              expression: "data.materialTypeCd",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }